import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import i18n from './Assets/Lang/i18n'
import { store } from './Backend/Redux/store/configureStore'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import CoreLayoutContainer from 'Frontend/Container/CoreLayoutContainer'
import { KEYSTORE, PATH } from './Utils/globalConstants'
import { checkLocalStoreToRedux } from './Backend/Redux/lib/reducerConfig'
import NotFoundPage from 'Frontend/Screen/NotFoundPage'
import * as actions from './Backend/Redux/actions/globalActions'
import init from './Backend/Redux/lib/initState'
import * as serviceWorker from './serviceWorker'
import InquiryScreen from 'Frontend/Screen/InquiryScreen'

export default class App extends React.PureComponent {

  componentDidMount = async () => {
    await checkLocalStoreToRedux(store, KEYSTORE.TRANSACTION_ID, actions.setTransactionID, init.transactionID)
    await checkLocalStoreToRedux(store, KEYSTORE.ALL_CURRENCIES, actions.setAllCurrencies, init.allCurrencies)
    await checkLocalStoreToRedux(store, KEYSTORE.STATUS_TRANSACTION, actions.setStatusTransaction, init.statusTransaction)
    
    setTimeout(() => {
      this.setState({
        isLoading: false
      })
    }, 1000)
  }
  render () {
    return (
      <Provider store={store}>
        <Router> 
          <Switch>
            <Route exact path="/" name="Home" component={CoreLayoutContainer} />
            <Route exact path={PATH.PAGE_NOT_FOUND} name="Page 404" component={NotFoundPage} />
            <Route exact path={PATH.INQUIRY} name="Inquiry"component={() => { 
                window.location.href = 'https://www.bacoor.io/send-a-ticket/'; 
                return null;
            }}/>
          </Switch>
        </Router>
      </Provider>
    )
  }
}

ReactDOM.render(<I18nextProvider i18n={ i18n() }>
  <App />
</I18nextProvider>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
