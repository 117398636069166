module.exports = {
  translations: {
    // Exchange
    'Exchange': `Exchange`,
    'PoweredByChangelly': `Powered by changelly`,
    'Floating': `Floating`,
    'Fixed': `Fixed`,
    'YouSend': `You send`,
    'Amount': `Amount`,
    'YouGetApproximately': `You get approximately`,
    'ExchangeNow': `Exchange now`,
    'TryFixedRateTransactions': `Try fixed rate transactions`,
    'EnableFixedRates': `Enable fixed rates`,
    'LearnMore': `Learn more`,
    '30Minutes': `5-30 minutes`,
    'PleaseInputYourAmount': `Please input your amount!`,
    'PleaseEnterCorrectFormatNumber': `Please enter correct format number!`,
    'FixedRateUpdatesEvery': `Fixed rate updates every`,
    '30Seconds': `30 seconds`,
    'ExchangeFee': `Exchange fee {{number}}%`,
    'ExchangeFee2': `{{number}} {{symbol}}`,
    'TheExchangeFee': `The exchange fee is already included in the displayed amount you’ll get`,
    'NetworkFee': `Network fee`,
    'WINTERSPECIAL': `WINTER SPECIAL`,
    'WillBeExcluded': `Will be excluded from the final amount`,
    'EstimatedArrival': `Estimated arrival`,
    'TransactionDetails': `Transaction details`,
    'WalletAddress': `Wallet address`,
    'address': 'address',
    'PleaseInputYourAddress': `Please input your address!`,
    'DownloadAWallet': `Download a wallet from the list of Changelly reliable partners in case you haven’t got one yet`,
    'RefundAddress': `Refund address`,
    'ProvideTheAddress': `Provide the address for refunding in case of any technical issues during the exchange`,
    'PleaseAgreeWithIt': `Please agree with it!`,
    'IAgreeWith': `I agree with Terms of Use, Privacy Policy and AML/KYC`,
    'Next': `Next`,
    'PopularCurrencies': `Popular currencies`,
    'TypeACurrency': `Type a currency or ticker`,
    'AllCurrencies': `All currencies`,
    'fixedRateAvailable': `fixed rate available`,
    'RecipientAddress': `Recipient address`,
    'FloatingExchangeRate': `Floating exchange rate`,
    'YourAmountCould': `Your amount could change depending on the market conditions.`,
    'FixedExchangeRate': `Fixed exchange rate`,
    'YourAmountWill': `Your amount will remain the same irrespective of the changes on the market.`,
    'FixedRateUpdates': `Fixed rate updates every 30 seconds`,
    'PleaseInputA': `Please input a number bigger than 0`,
    'PleaseEnterCorrect': `Please enter correct format email`,
    'Checkout': `Checkout`,
    'Back': `Back`,
    'PleaseInputYourEmail': `Please input your email!`,
    'SignUp': `Sign up to make payment`,
    'Email': `Email`,
    'email': `email`,
    'SendMePromos': `Send me promos, market news and product updates`,
    'SignUpMakePayment': `Make payment`,
    'Transaction': `Transaction ID: {{hash}}`,
    'PleaseSendTheExact': `Please send the exact amount from your wallet or exchange account to the following address`,
    'Send': `Send`,
    'ToAddress': `To address`,
    'YouHave36Hours': `You have 36 hours to send funds otherwise the transaction will be canceled automaticaly`,
    'CopySuccessfully': `Copy successfully!`,
    'PleaseEnterPositive': `Please enter positive integers and nonzero!`,
    'isNotAvailable': `{{symbol}} is not available for guaranteed rates`,
    'formatAddress': `Please enter correct format address`,
    'CalculateAmount': `Calculate amount`,
    'MinimumAmount': `Minimum amount: {{number}} {{symbol}}`,
    'MaximunAmount': `Maximun amount: {{number}} {{symbol}}`,
    'BackToExchange': `Back to Exchange Screen`,
    'Exchanging': `Exchanging`,
    'GettingConfirmations': `Getting confirmations`,
    'EthIsConfirmed': `{{symbol1}} is confirmed in the blockchain, we’ll start exchanging it to {{symbol2}}.`,
    'ExchangingEthto': `Exchanging {{symbol1}} to {{symbol2}}`,
    'TheProccessWillTake': `The proccess will take a few minutes. Please wait.`,
    'SendingToYourWallet': `Sending to your wallet`,
    'NowWeAreSending': `Now we are sending {{symbol}} to your wallet. Check the wallet, once the transaction is complete.`,
    'NewTransaction': `New transaction`,
    'NotCompleted': `Not completed`,
    'YourTransactionFailed': `Your transaction failed.`,
    'AmountFrom': `Amount from`,
    'AmountReceived': `Amount received`,
    'Completed': `Completed`,
    'AmountTo': `Amount to`,
    'AmountSent': `Amount sent`,
    'ExchangeRate': `Exchange rate`,
    'ExchangeRate2': `{{number1}} {{symbol1}} = {{number2}} {{symbol2}}`,
    'Inquiry': `Inquiry`,
    'Name': `Name`,
    'name': `name`,
    'What do you need help with?': `What do you need help with?`,
    'My tokens are not showing up': `My tokens are not showing up`,
    'Pending or failed transaction(s)': `Pending or failed transaction(s)`,
    'My balance is inaccurate': `My balance is inaccurate`,
    'Restoring my wallet and/or accounts': `Restoring my wallet and/or accounts`,
    'I lost or didn’t backup my seed phrase': `I lost or didn’t backup my seed phrase`,
    'Reporting a scam or phishing site': `Reporting a scam or phishing site`,
    'Unflagging a blocked website': `Unflagging a blocked website`,
    'Report a problem or bug with Pantagraph': `Report a problem or bug with Pantagraph`,
    'Suggest Feature': `Suggest Feature`,
    'Other': `Other`,
    'Subject': `Subject`,
    'subject': `subject`,
    'Description': `Description`,
    'Environment': `Environment`,
    'Browser extension': `Browser extension`,
    'Mobile app': `Mobile app`,
    'Web': `Web`,
    'Please specify the operating system type and version': `Please specify the operating system type and version`,
    'Pantograph version': `Pantograph version`,
    'Attachments': `Attachments`,
    'Please input': `Please input`,
    'Please input name': `Please input name!`,
    'Please input email': `Please input email!`,
    'Please input address': `Please input address!`,
    'Please input subject': `Please input subject!`,
    'must be maximum 50 characters.': `must be maximum 50 characters.`,
    'must be maximum 255 characters.': `must be maximum 255 characters.`,
    'Add file(s)': `Add file(s)`,
    'or drop file(s) here': `or drop file(s) here`,
    'Please enter the details of your request': `Please enter the details of your request. A member of our support staff will respond as soon as possible.`,
    'Your message had been sent. Thank you!': `Your message had been sent. Thank you!`,
    'This type is not supported!!': `This type is not supported!! Please use "doc", "docx", "xlsx", "pptx", "jpg", "png", "pdf"`,
    'Files must be maximum 5MB.': `Files must be maximum 5MB.`,
    'Drop the files here ...': 'Drop the files here ...',
    'Invalid Address': `Invalid Address`,
    'The input is not valid E-mail': `The input is not valid E-mail!`
  }
}