import React from "react";
import { Form, Input, Select, Icon, Button, Modal } from "antd";
import MainNav from 'Frontend/Screen/MainNav'
import MainFooter from 'Frontend/Screen/MainFooter'
import PantograpAPI from "Backend/Api/PantograpAPI";
import Dropfile from "./drop-file";
import SyncLoader from "react-spinners/SyncLoader";
import Swal from "sweetalert2";
import "antd/es/switch/style/index.css";
import "./styles.css";
import "./inquiry.styles.scss";
const { TextArea } = Input;
const { Option } = Select;
var ID = function() {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return (
    "_" +
    Math.random()
      .toString(36)
      .substr(2, 9)
  );
};

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = error => reject(error);
  });

class MainScreen extends React.Component {
  constructor(props) {
    super(props);
    this.inputFile = React.createRef();
    this.state = {
      errorFileExtension: false,
      files: [],
      loading: false,
      listErrorFile: []
    };
    this.handleChangeFiles = this.handleChangeFiles.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onReset = this.onReset.bind(this);
  }
  addFileToState = file => {};
  componentDidMount() {
    // this.props.loadingInitial();
    window.location.href = 'https://www.bacoor.io/send-a-ticket/'
  }
  handleChangeFiles = files => {
    const { t } = this.props;
    if (files.length) {
      let listErrorFile = [];
      for (let file of files) {
        let filename = file.name;
        const extension =
          filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
          filename;
        const extensionAccepted = [
          "doc",
          "docx",
          "xlsx",
          "pptx",
          "jpg",
          "png",
          "pdf"
        ];
        if (!extensionAccepted.includes(extension.toLowerCase())) {
          listErrorFile.push(t("This type is not supported!!"));
          break;
        }
      }
      let sumSizeOfFiles = 0;
      for (let file of this.state.files) {
        sumSizeOfFiles += file.size;
      }
      for (let file of files) {
        sumSizeOfFiles += file.size;
      }
      // limit file 5mb
      if (sumSizeOfFiles >= 5 * 1000 * 1000) {
        listErrorFile.push(t("Files must be maximum 5MB."));
      }
      if (listErrorFile.length === 0) {
        const arrayToBase64 = files.map(file => {
          return toBase64(file).then(base64 => ({
            id: ID(),
            name: file.name,
            data: base64,
            size: file.size
          }));
        });
        Promise.all(arrayToBase64).then(res =>
          this.setState({
            files: [...this.state.files, ...res],
            listErrorFile: []
          })
        );
      } else {
        this.setState({
          listErrorFile: listErrorFile
        });
      }
      // this.setState({
      //   files: listFile,
      //   errorFileExtension: false
      // });
    }
  };
  removeFileItemFromState = id => () => {
    this.setState({ files: this.state.files.filter(item => item.id !== id) });
  };
  handleSubmit = e => {
    const { t } = this.props;
    e.preventDefault();
    // console.log('this.props.form.validateFieldsAndScroll',this.props.form.validateFieldsAndScroll);
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("handleSubmit");
        const fileDataForReq = this.state.files.map(item => ({
          name: item.name,
          data: item.data
        }));
        values.attachments = fileDataForReq;
        this.setState({ loading: true });
        PantograpAPI.sendEmail(values)
          .then(res => {
            if (res.error === false) {
              this.onReset();
              Swal.fire({
                icon: "success",
                title: t("Your message had been sent. Thank you!"),
                showConfirmButton: false,
                timer: 2000
              });
            }
            this.setState({ loading: false });
          })
          .catch(err => {
            Swal.fire({
              icon: "error",
              title: err.message,
              showConfirmButton: false,
              timer: 3000
            });
            this.setState({ loading: false });
          });
      }
    });
  };
  validateAddress = (rule, value, callback) => {
    const { t } = this.props;
    let reg = /^0x[a-fA-F0-9]{40}$/;
    if (value !== "" && !reg.test(value)) {
      callback(t("Invalid Address"));
    }
    callback();
  };
  onReset = () => {
    this.setState({
      errorFileExtension: false,
      files: [],
      loading: false,
      listErrorFile: []
    });
    this.props.form.resetFields();
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { t } = this.props;
    return (
      <div className="container">
        <MainNav />
        <main>
          <div className="inquiry">
          <Modal className="modal-loader" centered visible={this.state.loading}>
            <SyncLoader
              // css={override}
              size={10}
              //size={"150px"} this also works
              color={"#fff"}
              loading={this.state.loading}
            />
          </Modal>
          <div className="inquiry-form">
            <h4>{t("Inquiry")}</h4>
            <Form onSubmit={this.handleSubmit} ref={this.inputFile}>
              <Form.Item label={t("Name")}>
                {getFieldDecorator("fullname", {
                  rules: [
                    {
                      required: true,
                      message: t("Please input name")
                    },
                    {
                      max: 50,
                      message: `${t("Name")} ${t(
                        "must be maximum 50 characters."
                      )}`
                    }
                  ]
                })(<Input />)}
              </Form.Item>
              <Form.Item label={t("Email")}>
                {getFieldDecorator("email", {
                  rules: [
                    {
                      type: "email",
                      message: `${t("The input is not valid E-mail")}`
                    },
                    {
                      required: true,
                      message: t("Please input email")
                    },
                    {
                      max: 50,
                      message: `${t("Email")} ${t(
                        "must be maximum 50 characters."
                      )}`
                    }
                  ]
                })(<Input />)}
              </Form.Item>
              <Form.Item label={t("WalletAddress")}>
                {getFieldDecorator("address", {
                  rules: [
                    {
                      required: true,
                      message: t('Please input address')
                    },
                    {
                      validator: this.validateAddress
                    }
                  ]
                })(<Input />)}
              </Form.Item>
              <Form.Item label={t("What do you need help with?")}>
                {getFieldDecorator(
                  "type",
                  {}
                )(
                  <Select>
                    <Option value={t("My tokens are not showing up")}>
                      {t("My tokens are not showing up")}
                    </Option>
                    <Option value={t("Pending or failed transaction(s)")}>
                      {t("Pending or failed transaction(s)")}
                    </Option>
                    <Option value={t("My balance is inaccurate")}>
                      {t("My balance is inaccurate")}
                    </Option>
                    <Option value={t("Restoring my wallet and/or accounts")}>
                      {t("Restoring my wallet and/or accounts")}
                    </Option>
                    <Option value={t("I lost or didn’t backup my seed phrase")}>
                      {t("I lost or didn’t backup my seed phrase")}
                    </Option>
                    <Option value={t("Reporting a scam or phishing site")}>
                      {t("Reporting a scam or phishing site")}
                    </Option>
                    <Option value={t("Unflagging a blocked website")}>
                      {t("Unflagging a blocked website")}
                    </Option>
                    <Option value={t("Report a problem or bug with Pantagraph")}>
                      {t("Report a problem or bug with Pantagraph")}
                    </Option>
                    <Option value={t("Suggest Feature")}>
                      {t("Suggest Feature")}
                    </Option>
                    <Option value={t("Other")}>{t("Other")}</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label={t("Subject")}>
                {getFieldDecorator("subject", {
                  rules: [
                    {
                      max: 50,
                      message: `${t("Subject")} ${t(
                        "must be maximum 50 characters."
                      )}`
                    },
                    {
                      required: true,
                      message: t("Please input subject")
                    }
                  ]
                })(<Input />)}
              </Form.Item>
              <Form.Item label={t("Description")}>
                {getFieldDecorator("message", {
                  rules: [
                    {
                      max: 255,
                      message: `${t("Description")} ${t(
                        "must be maximum 255 characters."
                      )}`
                    }
                  ]
                })(
                  <TextArea
                    placeholder={t("Please enter the details of your request")}
                    rows={4}
                  />
                )}
              </Form.Item>
              <Form.Item label={t("Environment")}>
                {getFieldDecorator(
                  "environment",
                  {}
                )(
                  <Select>
                    <Option value={t("Browser extension")}>
                      {t("Browser extension")}
                    </Option>
                    <Option value={t("Mobile app")}>{t("Mobile app")}</Option>
                    <Option value={t("Web")}>{t("Web")}</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="OS">
                {getFieldDecorator("os", {
                  rules: [
                    {
                      max: 50,
                      message: `OS ${t("must be maximum 50 characters.")}`
                    }
                  ]
                })(
                  <Input
                    placeholder={t(
                      "Please specify the operating system type and version"
                    )}
                  />
                )}
              </Form.Item>
              <Form.Item label={t("Pantograph version")}>
                {getFieldDecorator("version", {
                  rules: [
                    {
                      max: 50,
                      message: `${t("Pantograph version")} ${t(
                        "must be maximum 50 characters."
                      )}`
                    }
                  ]
                })(<Input />)}
              </Form.Item>
              <Form.Item label={t("Attachments") + " (5MB)"}>
                <div className="ctn-drop-file">
                  <Dropfile t={t} handleChangeFiles={this.handleChangeFiles} />
                  {this.state.listErrorFile.map((error, key) => (
                    <div key={key} style={{ color: "red", margin: "10px 0" }}>
                      {error}
                    </div>
                  ))}

                  {this.state.files.map(item => (
                    <div className="item-filename" key={item.id}>
                      <Icon type="paper-clip" />
                      {" " + item.name + " "}
                      <Icon
                        onClick={this.removeFileItemFromState(item.id)}
                        className="remove-filename"
                        type="close"
                      />
                    </div>
                  ))}
                </div>
              </Form.Item>
              <Button className="btnSend" htmlType="submit">
                {t("Send")}
              </Button>
            </Form>
          </div>
        </div>
        </main>
        <MainFooter />
      </div>
    );
  }
}
// {
//   "fullname": "string",
//   "email": "string",
//   "address": "string",
//   "subject": "string",
//   "type": "string",
//   "message": "string",
//   "environment": "string",
//   "os": "string",
//   "version": "string",
//   "attachments": [
//     "string"
//   ]
// }
export default Form.create({ name: "inquiry" })(MainScreen);
