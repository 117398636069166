import Settings from './apiSetting'
import axios from 'axios'

export default class ChatworkAPI {
  static async postMessage (message) {
    let url = Settings.server.chatworkApi.url +'rooms/' + Settings.server.chatworkApi.roomId + '/messages'
    const params = { body: 'ENV: ' + process.env.REACT_APP_ENV + ' - ' + message }
    try {
      let opt = {
        url,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'X-ChatWorkToken': process.env.REACT_APP_CHATWORK_TOKEN
        }
      }
      if (params) {
        opt.data = params
      }
      let response = await axios(opt)
      console.log('Chatwork post message: ', url, response)
      return response
    } catch (error) {
      console.log('Chatwork post message error: ', error)
    }
  }
}
