import ChatworkAPI from 'Backend/Api/ChatworkAPI'

export const isObject = (value) => {
  return value && typeof value === 'object' && value.constructor === Object
}

export const logDebugChatwork = (screen, error) => {
  ChatworkAPI.postMessage(screen + ': ' + ((isObject(error) ? JSON.stringify(error, undefined, 2) : error)))
}

export const roundingNumber = (number, rounding = 8) => {
  const powNumber = Math.pow(10, parseInt(rounding))
  return Math.floor(number * powNumber) / powNumber
}

export const numberWithCommasInt = (strNumber) => {
  return strNumber ? strNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0
}

export const validateNumber = (strNumber) => {
  var reg = /^([0-9_.]+)$/
  return reg.test(strNumber)
}

export const countDots = (strString, strLetter) => {
  return (String(strString).match(RegExp(strLetter, 'g')) || []).length
}

export const formatNumber = (strNumber, decimalFormat = 8) => {
  let first = '0'
  let second = ''
  decimalFormat = decimalFormat < 8 ? decimalFormat : 8
  isNaN(Number(strNumber)) && (strNumber = 0)
  strNumber = Number(strNumber).toFixed(10)
  let arrDigit = String(strNumber).split('.')
  if (arrDigit.length > 1 && decimalFormat > 0) {
    first = numberWithCommasInt(arrDigit[0])
    second = arrDigit[1].substring(0, decimalFormat).replace(/\.?0+$/, '')
  } else {
    first = numberWithCommasInt(arrDigit[0])
  }
  return second.length === 0 ? first : [first, second].join('.')
}

export const formatNumberCal = (strNumber, decimalFormat = 8) => {
  let first = '0'
  let second = ''
  decimalFormat = decimalFormat < 8 ? decimalFormat : 8
  isNaN(Number(strNumber)) && (strNumber = 0)
  strNumber = Number(strNumber).toFixed(10)
  let arrDigit = String(strNumber).split('.')
  if (arrDigit.length > 1 && decimalFormat > 0) {
    first = arrDigit[0]
    second = arrDigit[1].substring(0, decimalFormat).replace(/\.?0+$/, '')
  } else {
    first = arrDigit[0]
  }
  return second.length === 0 ? first : [first, second].join('.')
}

export const copyText = (data) => {
  let textField = document.createElement('textarea')
  textField.innerText = data
  document.body.appendChild(textField)
  textField.select()
  document.execCommand('copy')
  textField.remove()
}

export const saveDataLocal = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data))
}
export const getDataLocal = (key) => {
  return JSON.parse(localStorage.getItem(key))
}
export const removeDataLocal = (key) => {
  localStorage.removeItem(key)
}