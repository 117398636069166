import key from '../lib/constants'
import { KEYSTORE } from '../../../Utils/globalConstants'
import { saveDataLocal } from 'Utils/globalFunction'

export function setAuthorization (authorization) {
  return {
    type: key.SET_AUTHORIZZATION,
    payload: authorization
  }
}

export function setAllCurrencies (allCurrencies) {
  saveDataLocal(KEYSTORE.ALL_CURRENCIES, allCurrencies)
  return {
    type: key.SET_ALL_CURRENCIES,
    payload: allCurrencies
  }
}

export function setTransactionID (transactionID) {
  saveDataLocal(KEYSTORE.TRANSACTION_ID, transactionID)
  return {
    type: key.SET_TRANSACTION_ID,
    payload: transactionID
  }
}

export function setStatusTransaction (statusTransaction) {
  saveDataLocal(KEYSTORE.STATUS_TRANSACTION, statusTransaction)
  return {
    type: key.SET_STATUS_TRANSACTION,
    payload: statusTransaction
  }
}