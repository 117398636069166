import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './en/translations'
import jp from './jp/translations'
import cn from './cn/translations'
import vn from './vn/translations'

const options = {
  fallbackLng: 'en',
  whitelist: ['en', 'jp', 'cn'],
  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ','
  },

  react: {
    wait: true
  },

  resources: {
    en, jp, cn, vn
  }
}

export default () => {
  i18n
    .use(LanguageDetector)
    .init(options)
  return i18n
}
