import React from 'react'
import './styles.css'

const MainFooter = () => {
  return (
    <footer>
      @ 2020 Bacoor. All rights reserved. 
    </footer>
  )
}

export default MainFooter