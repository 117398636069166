module.exports = {
  translations: {
    // Simplex
    'Simplex.BuyBitcoin': `Bạn chỉ có thể mua {{name}} ở đây. 
    Nếu bạn muốn kiểm tra số dư {{name}} của bạn, vui lòng sử dụng một nền tảng khác.`,
    'Simplex.BuyCrypto': 'Mua tiền mã hóa',
    'Simplex.BuyCryptoDesc': `Acquiring Cryptocurrency has never been easier. Users now can purchase Bitcoin (BTC), Ethereum (ETH), and Ripple (XRP) with Credit Cards, or Debit Cards in a few simple steps.
    This feature is powered by Simplex.`,
    'Simplex.CoinChoose': 'Chọn tiền mã hóa',
    'Simplex.SelectAmount': 'Chọn số lượng',
    'Simplex.Min': 'Tối thiểu',
    'Simplex.Max': 'Tối đa',
    'Simplex.SelectAmountText1': 'Giao dịch này sẽ được thực hiện bởi bên thứ ba.',
    'Simplex.SelectAmountText2': 'Đã bao gồm phí giao dịch. ',
    'Simplex.AccountReceive': 'Chọn tài khoản người nhận',
    'Simplex.Buy': 'Mua',
    'Simplex.Cancel': 'Hủy',
    'Simplex.Tag': 'Tag',
    'Simplex.InvalidAddress': 'Địa chỉ không hợp lệ',
    'Simplex.ValidateNumber': 'Vui lòng nhập số',
    'Simplex.ValidateRequired': 'Vui lòng không để trống',
    'Simplex.LimitedValue': `Số tiền {{unit}} phải lớn hơn mức Tối thiểu và nhỏ hơn hoặc bằng mức Tối đa`,
    'Simplex.youPay': 'Bạn trả',
    'Simplex.youWillGet': 'Bạn sẽ nhận được',
    'Simplex.moreDetails': 'Chi tiết',
    'Simplex.receiverAccount': 'Tài khoản người nhận',
    'Simplex.invalidSession': 'Phiên làm việc của bạn đã hết hạn',
    'Simplex.requestFail': 'Yêu cầu thất bại!'
  }
}