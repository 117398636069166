const settings = {
  server: {
    chatApi: {
      url: process.env.REACT_APP_CHAT_API_URL
    },
    chatworkApi: {
      url: process.env.REACT_APP_CHATWORK_API_URL,
      token: process.env.REACT_APP_CHATWORK_TOKEN,
      roomId: process.env.REACT_APP_CHATWORK_ROOM_ID
    },
    paymentApi: {
      url: process.env.REACT_APP_PAYMENT_API_URL
    },
    nagemonApi: {
      url: process.env.REACT_APP_NAGEMON_API_URL
    },
    pantographApi: {
      url: process.env.REACT_APP_PANTOGRAPH_API_URL
    },
  }
}

export default settings