import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from '../reducers'
import logger from 'redux-logger'

// Middleware
const middleWare = [thunkMiddleware]

// Logger
if (process.env.REACT_APP_ENV !== 'production') {
  middleWare.push(logger)
}

const store = createStore(rootReducer, applyMiddleware(...middleWare))

export { store }