module.exports = {
  translations: {
    // Exchange
    'Exchange': `交换`,
    'PoweredByChangelly': `由changelly提供动力`,
    'Floating': `浮动中`,
    'Fixed': `固定`,
    'YouSend': `您发送`,
    'Amount': `金额`,
    'YouGetApproximately': `你大概会得到的`,
    'ExchangeNow': `现在交换`,
    'TryFixedRateTransactions': `尝试固定利率交易`,
    'EnableFixedRates': `启用固定费率`,
    'LearnMore': `了解详情`,
    '30Minutes': `5-30分钟`,
    'PleaseInputYourAmount': `请输入您的金额！`,
    'PleaseEnterCorrectFormatNumber': `请输入正确的格式编号！`,
    'FixedRateUpdatesEvery': `固定汇率每更新一次`,
    '30Seconds': `30秒`,
    'ExchangeFee': `交换费{{number}}％`,
    'ExchangeFee2': `{{number}} {{symbol}}`,
    'TheExchangeFee': `交换费已包含在您将获得的显示金额中`,
    'NetworkFee': `网络费用`,
    'WINTERSPECIAL': `冬季特惠`,
    'WillBeExcluded': `将从最终金额中排除`,
    'EstimatedArrival': `预计到货`,
    'TransactionDetails': `交易明细`,
    'WalletAddress': `钱包地址`,
    'PleaseInputYourAddress': `请输入您的地址！`,
    'DownloadAWallet': `以防万一，请从Changelly可靠合作伙伴列表中下载钱包`,
    'RefundAddress': `退款地址`,
    'ProvideTheAddress': `提供在交换过程中出现任何技术问题时退款的地址`,
    'PleaseAgreeWithIt': `请同意！`,
    'IAgreeWith': `我同意使用条款，隐私权政策和AML / KYC`,
    'Next': `下一步`,
    'PopularCurrencies': `大众货币`,
    'TypeACurrency': `输入货币或股票代码`,
    'AllCurrencies': `所有货币`,
    'fixedRateAvailable': `固定利率可用`,
    'RecipientAddress': `收件人地址`,
    'FloatingExchangeRate': `浮动汇率`,
    'YourAmountCould': `您的金额可能会根据市场情况而变化。`,
    'FixedExchangeRate': `固定汇率`,
    'YourAmountWill': `无论市场如何变化，您的金额都将保持不变。`,
    'FixedRateUpdates': `固定速率每30秒更新一次`,
    'PleaseInputA': `请输入大于0的数字`,
    'PleaseEnterCorrect': `请输入正确格式的电子邮件`,
    'Checkout': `结帐`,
    'Back': `返回`,
    'PleaseInputYourEmail': `请输入您的电子邮件！`,
    'SignUp': `注册付款`,
    'Email': `电子邮件`,
    'SendMePromos': `向我发送促销，市场新闻和产品更新`,
    'SignUpMakePayment': `付款`,
    'Transaction': `交易ID {{hash}}`,
    'PleaseSendTheExact': `请从您的钱包或兑换帐户中将确切的金额发送到以下地址`,
    'Send': `发送`,
    'ToAddress': `致地址`,
    'YouHave36Hours': `您有36小时的汇款时间，否则交易将被自动取消`,
    'CopySuccessfully': `复制成功！`,
    'PleaseEnterPositive': `请输入一个整数。`,
    'isNotAvailable': `{{symbol}}不适用于保证费率`,
    'formatAddress': `请输入正确的格式地址`,
    'CalculateAmount': `计算金额`,
    'MinimumAmount': `最低金额：{{number}} {{symbol}}`,
    'MaximunAmount': `最大金额：{{number}} {{symbol}}`,
    'BackToExchange': `返回交换画面`,
    'Exchanging': `交换中`,
    'GettingConfirmations': `检查中`,
    'EthIsConfirmed': `{{symbol1}}已在区块链上被识别。 然后交换为{{symbol2}}。`,
    'ExchangingEthto': `将{{symbol1}}替换为{{symbol2}}`,
    'TheProccessWillTake': `该过程需要几分钟。 请稍等。`,
    'SendingToYourWallet': `发送到您的钱包`,
    'NowWeAreSending': `您当前正在将{{symbol}}发送到您的钱包。 交易完成后，请检查您的钱包。`,
    'NewTransaction': `新交易`,
    'NotCompleted': `尚未完成`,
    'YourTransactionFailed': `交易失败。`,
    'AmountFrom': `交换数量`,
    'AmountReceived': `发送时间`,
    'Completed': `已完成`,
    'AmountTo': `您可以获得的数量`,
    'AmountSent': `寄出时间`,
    'ExchangeRate': `汇率`,
    'ExchangeRate2': `{{number1}} {{symbol1}} = {{number2}} {{symbol2}}`
  }
}