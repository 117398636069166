import Settings from './apiSetting'
import QueryString from 'query-string'
import axios from 'axios'
// import { logDebugChatwork } from 'Utils/globalFunction'

export default class PaymentAPI {
  /**
   * get request status
   * @returns func fetchData
   */

  static async getCurrenciesFull () {
    let extention = 'currencies-full'
    const params = {}
    return this.fetchDataWithAuth('POST', null, extention, params)
  }

  static async sendAndGet (from, to, amount) {
    let extention = 'exchange-amount'
    const params = {from, to, amount}
    return this.fetchDataWithAuth('POST', null, extention, params)
  }

  static async getFixRateAmount (from, to, amount) {
    let extention = 'get-fix-rate-amount'
    const params = {from, to, amount}
    return this.fetchDataWithAuth('POST', null, extention, params)
  }

  static async getFixRate (from, to) {
    let extention = 'get-fix-rate'
    const params = {from, to}
    return this.fetchDataWithAuth('POST', null, extention, params)
  }

  static async getMinAmount (from, to) {
    let extention = 'min-amount'
    const params = {from, to}
    return this.fetchDataWithAuth('POST', null, extention, params)
  }

  static async checkAddress (currency, address) {
    let extention = 'validate-address'
    const params = {currency, address}
    return this.fetchDataWithAuth('POST', null, extention, params)
  }

  static async createTransaction (from, to, address, amount, email) {
    let extention = 'create-transaction'
    const params = {from, to, address, amount, email}
    return this.fetchDataWithAuth('POST', null, extention, params)
  }

  static async createTransactionFixed (from, to, rateId, address, refundAddress, amountFrom) {
    let extention = 'create-transaction-fixed'
    const params = {from, to, rateId, address, refundAddress, amountFrom}
    return this.fetchDataWithAuth('POST', null, extention, params)
  }

  static async getStatus (id) {
    let extention = 'get-status'
    const params = {id}
    return this.fetchDataWithAuth('POST', null, extention, params)
  }

  static async sendEmail (data) {
    let extention = 'mail'
    const params = data
    return this.fetchDataWithAuth('POST', null, extention, params)
  }
  /**
   * request payment 
   * @returns func fetchData
   */
  static async requestPayment (data, address, authorization) {
    let extention = `address/${address}/payment-request`
    const params = data
    return this.fetchDataWithAuth('POST', authorization, extention, params)
  }
  /**
   * fetchDataWithAuth with authentication
   * @param {*} queryStr
   * @param {*} method
   * @returns respon json
   */
  static async fetchDataWithAuth (method, authorization, extention, params) {
    const callAPI = new Promise(async (resolve, reject) => {
      let url = Settings.server.pantographApi.url + extention
      if (method === 'GET' && params) {
        url = url + '?' + QueryString.stringify(params)
      }
      try {
        let opt = {
          url,
          method: method,
          mode: 'no-cors',
          headers: {
            'authorization': authorization ? 'Bearer ' + authorization : '',
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
        if (method !== 'GET' && params) {
          opt.data = params
        }
        let response = await axios(opt)
        resolve(response.data)
      } catch (error) {
        // logDebugChatwork('PaymentAPI fetch data error: ', error + '; router: ' + url)
        reject(error)
      }
    })

    // Close promise if over time
    const callTimeout = () => new Promise((resolve, reject) => setTimeout(reject, 60000, 'OVER_TIME'))

    return Promise.race([callAPI, callTimeout()]).then((result) => {
      return result
    }).catch((e) => {
      return null
    })
  }
}