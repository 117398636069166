import React from 'react'
import { Route, Switch } from 'react-router-dom'
import './antd.css'
import './styles.css'
import routes from 'Utils/mainRoutes'
import MainNavExchange from 'Frontend/Screen/MainNavExchange'
import MainFooter from 'Frontend/Screen/MainFooter'
import { useTranslation } from 'react-i18next'

const CoreLayoutContainer = () => {
  const { i18n } = useTranslation()
  return (
    <div className={'container-' + i18n.language}>
      <MainNavExchange />
      <main>
        <Switch>
          {routes.map((route, idx) => {
              return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                  <route.component {...props} />
                )} />)
                : (null);
            },
          )}
        </Switch>
      </main>
      <MainFooter />
    </div>
  )
}

export default CoreLayoutContainer