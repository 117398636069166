import PantograpAPI from 'Backend/Api/PantograpAPI'
import { setAllCurrencies, setTransactionID } from 'Backend/Redux/actions/globalActions'
import { logDebugChatwork } from 'Utils/globalFunction'
import moment from 'moment'

const arrPopular = ['btc', 'eth', 'xrp']
var THIS
export const defaultThis = (_this) => {
  return (dispatch, getState) => {
    THIS = _this
  }
}

export const loadingInitial = () => {
  return async (dispatch, getState) => {
    const { transactionID } = THIS.props
    try {
      let allCurrencies = await PantograpAPI.getCurrenciesFull()
      if (transactionID.data) {
        let getStatus = await PantograpAPI.getStatus(transactionID.data.id)
        if (getStatus && !getStatus.data === 'waiting') {
          dispatch(setTransactionID(''))
        }
      }
      if (allCurrencies && !allCurrencies.error && allCurrencies.data) {
        dispatch(setAllCurrencies(allCurrencies.data))
      }
    } catch (error) {
      logDebugChatwork('loadingInitial', error)
    }
  }
}

export const onSendAndGet = (from, to, amount) => {
  return async (dispatch, getState) => {
    await PantograpAPI.sendAndGet(from, to, amount)
    console.log(`onSendAndGet: `, from, to, amount);
  }
}

export const onChangeSwitch = (checked) => {
  return async (dispatch, getState) => {
    console.log(`switch to ${checked}`);
  }
}

export const handleVisibleChange = (visible) => {
  return async (dispatch, getState) => {
    THIS.setState({ visible });
  }
}

export const handleVisibleInfo = (visibleInfo) => {
  return async (dispatch, getState) => {
    THIS.setState({ visibleInfo });
  }
}

export const onExchange = (values) => {
  return async (dispatch, getState) => {
    THIS.setState({ exchangeScreen: false })
    if (values) {
      THIS.setState({ dataExchange: values })
    }
    console.log('onExchange: ', values)
  }
}

export const onCalculate = (values) => {
  return async (dispatch, getState) => {
    THIS.setState({ calculateScreen: false })
    if (values) {
      THIS.setState({ dataExchange: values })
    }
    console.log('onCalculate: ', values)
  }
}

export const onBackCalculate = (values) => {
  return async (dispatch, getState) => {
    THIS.setState({ calculateScreen: true })
    console.log('onBackCalculate: ', values)
  }
}

export const BackToExchange = () => {
  return async (dispatch, getState) => {
    console.log('BackToExchange')
    THIS.setState({ 
      calculateScreen: true,
      checkoutScreen: true
    })
    dispatch(setTransactionID(''))
  }
}

export const onCheckout = (values) => {
  return async (dispatch, getState) => {
    if (values) {
      let createTransaction
      if (values.rateId) {
        createTransaction = await PantograpAPI.createTransactionFixed(
          values.sendToken.name,
          values.getToken.name,
          values.rateId,
          values.recipientAddress,
          values.refundAddress,
          values.sendAmount,
        )
      } else {
        createTransaction = await PantograpAPI.createTransaction(
          values.sendToken.name,
          values.getToken.name,
          values.recipientAddress,
          values.sendAmount,
          values.email,
        )
      }
      console.log('createTransaction: ', createTransaction)
      if (createTransaction && createTransaction.status === 'success') {
        createTransaction.data.countDown = moment(createTransaction.data.createdAt).add(30, 'm').valueOf()
        THIS.setState({ 
          dataExchange: createTransaction,
          checkoutScreen: false
        })
        dispatch(setTransactionID(createTransaction))
      }
    }
    console.log('onCheckout: ', values)
  }
}

export const onShowTransaction = (value) => {
  return async (dispatch, getState) => {
    THIS.setState({ showTransaction: value })
  }
}