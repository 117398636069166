export const PATH = {
  PAGE_NOT_FOUND: '/404',
  // INQUIRY: '/inquiry'
}

export const KEYSTORE = {
  TRANSACTION_ID: 'TransactionId',
  ALL_CURRENCIES: 'AllCurrencies',
  STATUS_TRANSACTION: 'StatusTransaction'
}

export const STATUS_TRANSACTION = {
  WAITING: 'waiting',
  CONFIRMING: 'confirming',
  EXCHANGING: 'exchanging',
  SENDING: 'sending',
  FINISHED: 'finished',
  FAILED: 'failed'
}

export const arrPopular = ['tomo', 'btc', 'eth', 'xrp']

export const ETH = { 
  'fullName': 'Ethereum',
  'name': 'eth',
  'fixRateEnabled': true
}

export const TOMO = { 
  'fullName': 'TomoChain',
  'name': 'tomo',
  'fixRateEnabled': false
}