const Images = {
  // crypto
  bitcoinIcon: require('./crypto/bitcoin.png'),
  etherIcon: require('./crypto/ether.png'),
  rippleIcon: require('./crypto/ripple.png'),
  tronIcon: require('./crypto/tron.png'),
  // icon
  logoPantograph: require('./icon/logoPantograph.svg'),
  Tips: require('./icon/Tips.svg'),
  Lock: require('./icon/Lock.svg'),
  lockWhite: require('./icon/lockWhite.svg'),
  lockBlue: require('./icon/lockBlue.svg'),
  iconBTC: require('./icon/iconBTC.svg'),
  iconDefault: require('./icon/iconDefault.svg'),
  check: require('./icon/check.svg'),
  closeIcon: require('./icon/closeIcon.svg'),
  searchIcon: require('./icon/searchIcon.svg'),
  lockCircle: require('./icon/lockCircle.svg'),
  lockCircle1: require('./icon/lockCircle1.svg'),
  fixedClose: require('./icon/fixedClose.svg'),
  diogram: require('./icon/diogram.svg'),
  Arrows: require('./icon/Arrows.svg'),
  iconBack: require('./icon/iconBack.svg'),
  iconCopy: require('./icon/iconCopy.svg'),
  download: require('./icon/download.svg'),
  tick: require('./icon/tick.svg'),
  sending: require('./icon/sending.svg'),
  exchanging: require('./icon/exchanging.svg'),
  gettingInfo: require('./icon/gettingInfo.svg'),
  closeIcon2: require('./icon/closeIcon2.svg'),
  iconCompleted: require('./icon/iconCompleted.svg'),
  icSelected: require('./icon/ic-selected.svg')
}
export default Images