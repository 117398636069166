import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

function Dropzone(props) {
  const onDrop = useCallback(acceptedFiles => {
    const { handleChangeFiles } = props;
    handleChangeFiles(acceptedFiles);
    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const { t } = props;
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div className="dropzone">
        {isDragActive ? (
          <div>{t('Drop the files here ...')}</div>
        ) : (
          <div>
            {" "}
            <span className="blue">{t('Add file(s)')} </span> {t('or drop file(s) here')}
          </div>
        )}
      </div>
    </div>
  );
}

export default Dropzone;
