module.exports = {
  translations: {
    // Exchange
    'Exchange': `交換`,
    'PoweredByChangelly': `Changellyにより提供`,
    'Floating': `フローティング`,
    'Fixed': `固定`,
    'YouSend': `あなたが交換したい数量`,
    'Amount': `数量`,
    'YouGetApproximately': `あなたがもらえる数量`,
    'ExchangeNow': `すぐに交換`,
    'TryFixedRateTransactions': `固定金利取引をお試しください`,
    'EnableFixedRates': `固定料金を有効にする`,
    'LearnMore': `もっと詳しく`,
    '30Minutes': `5〜30分`,
    'PleaseInputYourAmount': `数量を入力してください！`,
    'PleaseEnterCorrectFormatNumber': `正しい番号形式を入力してください！`,
    'FixedRateUpdatesEvery': `固定レートは毎回更新されます`,
    '30Seconds': `30秒`,
    'ExchangeFee': `交換手数料{{number}}%`,
    'ExchangeFee2': `{{number}} {{symbol}}`,
    'TheExchangeFee': `交換手数料は、表示される数量に含まれています`,
    'NetworkFee': `ネットワーク料`,
    'WINTERSPECIAL': `スペシャル`,
    'WillBeExcluded': `最終金額から除外されます`,
    'EstimatedArrival': `到着予定`,
    'TransactionDetails': `取引の詳細`,
    'WalletAddress': `ウォレットアドレス`,
    'address': 'ウォレットアドレス',
    'PleaseInputYourAddress': `アドレスを入力してください！`,
    'DownloadAWallet': `Changellyの信頼できるパートナーのリストからウォレットをダウンロードします`,
    'RefundAddress': `払い戻し先`,
    'ProvideTheAddress': `交換中に技術的な問題が発生した場合の払い戻し先を指定してください`,
    'PleaseAgreeWithIt': `同意しますか。`,
    'IAgreeWith': `利用規約、プライバシーポリシー、およびAML / KYCに同意します`,
    'Next': `次へ`,
    'PopularCurrencies': `人気がある通貨`,
    'TypeACurrency': `通貨またはティッカーを入力してください`,
    'AllCurrencies': `すべての通貨`,
    'fixedRateAvailable': `利用可能な固定料`,
    'RecipientAddress': `受取人のアドレス`,
    'FloatingExchangeRate': `変動為替レート`,
    'YourAmountCould': `市場の状況に応じて数量が変更される可能性があります。`,
    'FixedExchangeRate': `固定為替レート`,
    'YourAmountWill': `市場の変化に関係なく、数量は変更されません。`,
    'FixedRateUpdates': `30秒ごとの固定レートを更新`,
    'PleaseInputA': `0より大きい数値を入力してください`,
    'PleaseEnterCorrect': `正しい形式のメールアドレスを入力してください`,
    'Checkout': `チェックアウト`,
    'Back': `バック`,
    'PleaseInputYourEmail': `メールアドレスを入力してください！`,
    'SignUp': `サインアップして支払いましょう`,
    'Email': `メール`,
    'email': `メール`,
    'SendMePromos': `プロモーション、市場ニュース、製品の更新を送信`,
    'SignUpMakePayment': `支払いましょう`,
    'Transaction': `トランザクションID：{{hash}}`,
    'PleaseSendTheExact': `ウォレットまたは取引アカウントから正確な数量を次のアドレスに送ってください`,
    'Send': `送信`,
    'ToAddress': `アドレス`,
    'YouHave36Hours': `36時間以内に送金してください。そうしないと、取引は自動的にキャンセルされます`,
    'CopySuccessfully': `コピーしました。`,
    'PleaseEnterPositive': `整数を入力してください`,
    'isNotAvailable': `{{symbol}}は保証レートがありません`,
    'formatAddress': `正しい形式のアドレスを入力してください`,
    'CalculateAmount': `数量を計算する`,
    'MinimumAmount': `最低額：{{number}} {{symbol}}`,
    'MaximunAmount': `最大額：{{number}} {{symbol}}`,
    'BackToExchange': `交換画面に戻る`,
    'Inquiry': `お問い合わせ`,
    'Name': `名前`,
    'name': `名前`,
    'What do you need help with?': `何かお困りですか`,
    'My tokens are not showing up': `トークンが表示されない。`,
    'Pending or failed transaction(s)': `トランザクションが保留中または失敗`,
    'My balance is inaccurate': `正しくない残高が表示される。`,
    'Restoring my wallet and/or accounts': `ウォレット又はアカウントを復元できない。`,
    'I lost or didn’t backup my seed phrase': `パスフレーズを紛失した。`,
    'Reporting a scam or phishing site': `フィッシング詐欺の報告`,
    'Unflagging a blocked website': `ウェブサイトのブロックを解除する。`,
    'Report a problem or bug with Pantagraph': `パンタグラフでエラーが発生した。`,
    'Suggest Feature': `機能提案`,
    'Other': `その他`,
    'Subject': `タイトル`,
    'subject': `タイトル`,
    'Description': `お問い合わせ内容`,
    'Environment': `環境`,
    'Browser extension': `ブラウザエクステンション`,
    'Mobile app': `モバイルアプリ`,
    'Web': `ウェブ`,
    'Please specify the operating system type and version': `バージョンや種類をご確認ください。`,
    'Pantograph version': `パンタグラフバージョン`,
    'Attachments': `添付ファイル`,
    'Please input': `入力してください`,
    'Please input name': `名前を入力してください!`,
    'Please input email': `メールを入力してください!`,
    'Please input address': `ウォレットアドレスを入力してください!`,
    'Please input subject': `タイトルを入力してください!`,
    'must be maximum 50 characters.': `最大50文字までです。`,
    'must be maximum 255 characters.': `最大255文字までです。`,
    'Add file(s)': `ファイルを添付`,
    'or drop file(s) here': `またはここにファイルをドロップして下さい`,
    'Please enter the details of your request': `リクエストの詳細を入力してください。`,
    'Your message had been sent. Thank you!': `メッセージが送信されました。 ありがとうございました！`,
    'This type is not supported!!': `このタイプはサポートされていません!! 「doc」、「docx」、「xlsx」、「pptx」、「jpg」、「png」、「pdf」を使用してください`,
    'Files must be maximum 5MB.': `ファイルは最大5MBまでです`,
    'Drop the files here ...': 'こちらにファイルをドロップして下さい',
    'Invalid Address': `無効なアドレス`,
    'The input is not valid E-mail': `有効なメールではありません`
  }
}