import view from './view';
import { connect } from 'react-redux';
import * as mapActionsToProps from './actions';
import { withTranslation } from 'react-i18next';
import { Form, Input } from "antd";

const mapStateToProps = (state) => ({
  ...state
});

const connectToRedux = Form.create({ name: "inquiry" })(connect(mapStateToProps, mapActionsToProps)(view));

export default withTranslation()(view);