import React from 'react'
import Loadable from 'react-loadable'

const Loading = () => {
  return <div></div>
}

const MainScreen = Loadable({
  loader: () => import('../Frontend/Screen/MainScreen'),
  loading: Loading,
})

const NotFound = Loadable({
  loader: () => import('../Frontend/Screen/NotFoundPage'),
  loading: Loading,
})

const routes = [
  { path: '/', exact: true, name: 'Home', component: MainScreen },
  { path: '*', name: '404', component: NotFound }
]

export default routes