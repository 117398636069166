import React from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'
import './styles.css'
const { Option } = Select

const MainNav = () => {
  const { i18n, t } = useTranslation()
  return (
    <header>
      <div className="wrapper">
        <div className="main-nav-exchange">
            <div className="site-title">{t('Exchange')}</div>
          <div className="site-desc">{t('PoweredByChangelly')}</div>
          <div className="language-switcher">
            <Select defaultValue="en" value={i18n.language} onChange={(e) => i18n.changeLanguage(e)}>
              <Option value="en">EN</Option>
              <Option value="jp">JP</Option>
              <Option value="cn">CN</Option>
            </Select>
          </div>
        </div>
      </div>
    </header>
  )
}

export default MainNav