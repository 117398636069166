import key from '../lib/constants'
import init from '../lib/initState'
import createReducer from '../lib/reducerConfig'

export const authorization = createReducer(init.authorization, {
  [key.SET_AUTHORIZZATION] (state, action) {
    return action.payload
  }
})

export const allCurrencies = createReducer(init.allCurrencies, {
  [key.SET_ALL_CURRENCIES] (state, action) {
    return action.payload
  }
})

export const transactionID = createReducer(init.transactionID, {
  [key.SET_TRANSACTION_ID] (state, action) {
    return action.payload
  }
})

export const statusTransaction = createReducer(init.statusTransaction, {
  [key.SET_STATUS_TRANSACTION] (state, action) {
    return action.payload
  }
})